<template>
  <v-row>
    <v-col
      cols="auto"
      v-for="chip in chips"
      :key="chip.key"
    >
      <v-chip small>
        {{ chip.value }} {{ chip.text }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
	name: "CategoryInformation",
	props: {
		category: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			//
		}
	},
	computed: {
		chips() {
			return [
				{
					id: 1,
					key: 'uuid',
					text: '',
					value: this.category.uuid
				},
				{
					id: 2,
					key: 'items-length',
					text: this.$t('itemsInCategory'),
					value: this.itemsInCategory().length
				},
				{
					id: 3,
					key: 'etags-length',
					text: this.$t('itemsInEtags'),
					value: this.itemsInEtags().length
				},
				{
					id: 4,
					key: 'weight',
					text: '',
					value: this.category.weight
				}
			]
		},
		items() {
			return this.$store.state.items
		},
	},
	methods: {
		itemsInCategory() {
			return this.items.filter(item => item.categories && item.categories.length && item.categories.some(c => c.uuid === this.category.uuid))
		},
		itemsInEtags() {
			return this.itemsInCategory().filter(item => item.categories && item.categories.length && item.categories.some(c => c.name === 'eTags'))
		},
	}
}
</script>

<style scoped>

</style>